<script setup lang="ts">
import ChevronArrow from '@/assets/images/global/chevron.svg'
import Logo from '@/assets/images/global/logo-green.svg'
import { useWindowScroll } from '@vueuse/core'

const isExpand = ref(false)
const { screens } = utils

const menuItems = ref([
  { title: 'Home', path: '/', hash: '#home' },
  {
    title: 'Games',
    group: 'games',
    path: '/',
    hash: '#games',
    toggle: false,
    children: [
      { title: 'Questland', path: '/games/questland' },
      { title: 'Slash&Roll', path: '/games/slash-and-roll' },
      { title: 'The Gang', path: '/games/the-gang' },
      { title: 'Mafia Rivals', path: '/games/mafia-rivals' },
      { title: 'Soccer Clash', path: '/games/soccer-clash' },
      { title: 'Hero Legacy', path: '/games/hero-legacy' },
      { title: 'Fable Wars', path: '/games/fable-wars' },
    ],
  },
  { title: 'About us', path: '/', hash: '#about' },
  { title: 'Benefits', path: '/', hash: '#benefits' },
  // { title: 'Contact us', path: '/', hash: '#contact' },
])

function scrollToElement(targetId: string, haveChildren = false) {
  if (targetId && !(haveChildren && !screens.value.big.alLeast)) {
    const element = document.getElementById(targetId)
    element?.scrollIntoView?.({ behavior: 'smooth' })
  }
}

const { y: windowScrollY } = useWindowScroll()
const scrollObserver = ref({
  y: 0,
  yPrev: 0,
  isHiddenHeader: false,
})
function initWatchScrollY() {
  if (!screens.value.big.alLeast) {
    return watch(
      () => windowScrollY.value,
      (scrollY) => {
        scrollObserver.value.isHiddenHeader = !(scrollY <= 250 || scrollObserver.value.yPrev > scrollY)
        scrollObserver.value.yPrev = scrollY
      },
    )
  }
  return null
}
let unWatchScrollY = initWatchScrollY()

watch(
  () => screens.value.big.alLeast,
  () => {
    if (screens.value.big.alLeast && unWatchScrollY !== null) {
      unWatchScrollY()
      unWatchScrollY = null
    } else if (unWatchScrollY === null) {
      unWatchScrollY = initWatchScrollY()
    }
  },
)
</script>

<template>
  <header
    class="fixed top-0 z-50 max-h-[90vh] w-full overflow-auto bg-white py-3 big:overflow-visible"
    :class="{ 'is-hidden': !screens.big.alLeast && scrollObserver.isHiddenHeader }"
  >
    <div class="mx-auto flex h-full max-w-screen-lg justify-between px-8 big:items-center lg:px-0">
      <router-link to="/">
        <Logo class="h-9 w-9 big:h-12 big:w-12" />
      </router-link>
      <nav class="flex flex-col items-end big:flex-row big:items-center">
        <div class="flex h-9 items-center justify-center big:hidden">
          <button-hamburger-component v-model="isExpand" />
        </div>
        <transition name="accordion-transition">
          <div
            v-if="isExpand || screens.big.alLeast"
            class="grid transition-all duration-300 ease-in"
          >
            <div class="overflow-hidden">
              <ul class="flex flex-col big:!max-h-full big:flex-row">
                <li
                  v-for="(item, itemIndex) in menuItems"
                  :key="itemIndex"
                  class="cursor-pointer px-0 py-[3px] text-right big:px-6 big:py-0 big:text-left"
                  @click="scrollToElement(item?.hash.slice(1), !!item?.children?.length)"
                >
                  <div>
                    <router-link
                      v-slot="{ href, navigate, isActive }"
                      :to="item.path + item.hash"
                      custom
                    >
                      <span
                        v-if="!screens.big.alLeast && item?.children?.length"
                        :class="[((isActive && $route.hash === item?.hash) || (item.group && item.group === String($route.name).substring(0, item.group.length))) && 'router-link-active']"
                        :title="item.title"
                        @click="item.toggle = !item.toggle"
                      >
                        <ChevronArrow
                          class="-mt-0.5 inline-block scale-90 transition-all duration-500"
                          :class="{ '-rotate-90': !item.toggle }"
                        />
                        {{ item.title }}
                      </span>
                      <a
                        v-else
                        :href="href"
                        :class="[((isActive && $route.hash === item?.hash) || (item.group && item.group === String($route.name).substring(0, item.group.length))) && 'router-link-active']"
                        :title="item.title"
                        @click="navigate($event), (isExpand = false)"
                      >{{ item.title }}</a>
                    </router-link>
                  </div>
                  <template v-if="item?.children?.length">
                    <transition name="accordion-transition">
                      <div
                        v-if="item.toggle || screens.big.alLeast"
                        class="submenu big:absolute big:z-10 big:hidden big:-translate-x-5 grid transition-all duration-500 ease-in"
                      >
                        <div class="overflow-hidden">
                          <div class="mt-1 border-r-2 border-gs-bg bg-white pr-5 big:mt-5 big:border-r-0 big:pr-0 big:shadow-md">
                            <router-link
                              v-for="(child, index) in item.children"
                              :key="index"
                              :to="child.path"
                              :title="child.title"
                              class="block cursor-pointer py-[3px] big:px-5 big:hover:bg-gray-100"
                              @click.stop="isExpand = false"
                            >
                              {{ child.title }}
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </template>
                </li>
                <li class="cursor-pointer px-0 py-[3px] text-right big:px-6 big:py-0 big:text-left">
                  <a href="https://store.gamesture.com/">Our shop</a>
                </li>
                <li class="mt-2 border-t border-solid px-0 pt-2 text-right big:mt-auto big:border-l big:border-t-0 big:px-6 big:py-0 big:text-left">
                  <router-link
                    to="/jobs"
                    title="Jobs"
                    @click="isExpand = false"
                  >
                    Jobs
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </nav>
    </div>
  </header>
</template>

<style scoped lang="postcss">
header {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  transition: top 0.5s ease-in-out;
  &.is-hidden {
    top: -100vh !important;
  }
}
ul {
  li {
    font-size: 0.875rem;
    letter-spacing: 0.1px;

    &:last-child {
      border-color: #62656a;
      padding-right: 0;
    }
  }
}
@media (min-width: theme('screens.big')) {
  ul {
    li {
      &:hover {
        .submenu {
          display: block !important;
        }
      }
    }
  }
}
</style>
